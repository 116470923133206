* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #f8f8f8;
  color: #D7D9D7;
}

body, input, button, textarea {
  font: 400 16px 'Poppins', sans-serif;
}